/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from '@mui/material';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import ClientService from '../../../../../Services/ClientService';
import validateSettingsForm from '../../validateSettingsForm';
import XpektorButton from '../../../../../Components/XpektorButton/XpektorButton';
/* eslint-disable react/prop-types */

// eslint-disable-next-line react/prop-types
function CreateClientPopUp({ handleClose, action, clientToEdit }) {
  const [errors, setErrors] = useState({});
  const formNumber = 2;
  const [client, setClient] = useState(clientToEdit);

  const handleChange = (e, set) => {
    let clientErrors;
    if (set === 3) {
      clientErrors = validateSettingsForm(formNumber, set, e.target.value);
    } else {
      clientErrors = validateSettingsForm(formNumber, set, e.target.value);
    }
    setErrors(clientErrors);
    setClient((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) {
      alert('Det finns fel i formuläret');
    } else if (action === 'createClient') {
      await ClientService.updateClient(client)
        .then((response) => {
          if (response) {
            window.location = `/settings/clients?action=connectBank&clientId=${clientToEdit.id}`;
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert('Du har ej behörighet att skapa klient');
          }
          if (error.response.status === 404) {
            alert('Kunde inte lägga till klient');
          }
          if (error.response.status === 406) {
            alert('Du har ej behörighet att skapa klient');
          }
          if (error.response.status === 500) {
            alert('Något gick fel, försök igen senare');
          }
        });
    } else if (action === 'updateClient') {
      await ClientService.updateClient(client)
        .then((response) => {
          if (response) {
            window.location = `/settings/clients`;
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert('Du har ej behörighet att ändra klienten');
          }
          if (error.response.status === 404) {
            alert('Kunde inte ändra klient');
          }
          if (error.response.status === 406) {
            alert('Du har ej behörighet att ändra klienten');
          }
          if (error.response.status === 500) {
            alert('Något gick fel, försök igen senare');
          }
        });
    }
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0"
    >
      <Modal.Header className="border-0">
        <Modal.Title>
          {action === 'updateClient' ? 'Ändra ' : 'Skapa ny '}klient
        </Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <TextField
              type="text"
              name="name"
              label="Namn"
              variant="outlined"
              placeholder={clientToEdit.name}
              onChange={(e) => handleChange(e, 1)}
              value={client.name}
              error={errors.newClientName}
              helperText={errors.newClientName}
              className="w-100"
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <TextField
              type="text"
              name="organizationNumber"
              label="Organistationsnummer"
              variant="outlined"
              placeholder={clientToEdit.organizationNumber}
              onChange={(e) => handleChange(e, 2)}
              value={client.organizationNumber}
              error={errors.newOrgNumber}
              helperText={errors.newOrgNumber}
              className="w-100"
              disabled
            />
          </Form.Group>
          <br />
          {/*
          <Form.Group>
            <TextField
              type="text"
              name="shareCapital"
              label="Aktiekapital"
              variant="outlined"
              placeholder={clientToEdit.shareCapital}
              onChange={(e) => handleChange(e, 4)}
              value={client.shareCapital}
              error={errors.newShareCapital}
              helperText={errors.newShareCapital}
              className="w-100"
              disabled
            />
          </Form.Group> */}
          <br />
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <div className="flex-row">
          <XpektorButton content="Bekräfta" onClick={handleSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateClientPopUp;
