/* eslint-disable react/prop-types */
import './TransactionTable.scss';
import EyeIcon from '../../../../../../Images/Eye.png';

export default function TransactionTable({ children }) {
  return <div className="transaction-table">{children}</div>;
}

TransactionTable.Item = function Item({ transaction, onClick }) {
  const amountSigned =
    transaction.creditDebitIndicator === 'DBIT'
      ? transaction.transactionAmount.amount * -1
      : transaction.transactionAmount.amount * 1; // to get rid of decimals

  return (
    <button className="transaction-item" onClick={onClick} type="button">
      <img
        className="transaction-item-logo"
        src={transaction.logo ?? EyeIcon}
        alt="Logo"
      />
      <div className="transaction-item-info">
        <p className="transaction-name">{transaction.name}</p>
        <p className="transaction-remittance">
          {transaction.remittanceInformation}
        </p>
      </div>
      <div className="transaction-item-money">
        <p>{amountSigned}</p>
        <p>{transaction.transactionAmount.currency}</p>
      </div>
    </button>
  );
};
