/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  faExclamationTriangle,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import Module from './Module';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import { TranslateTriggers } from '../../../../Components/Utilities/TriggerTexts';

export default function Liquidity({ liquidityStatus, onPdfClick }) {
  const [translatedTriggers, setTranslatedTriggers] = useState([]);

  useEffect(() => {
    if (liquidityStatus !== null && Object.keys(liquidityStatus).length > 0) {
      setTranslatedTriggers(TranslateTriggers(liquidityStatus.triggers));
    }
  }, [liquidityStatus]);

  return (
    <Module>
      {liquidityStatus == null ? (
        <p className="module-empty-text">Finns ingen data för Likviditet.</p>
      ) : liquidityStatus.liquidityReport == null ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : (
        <>
          <Module.Nav>
            <div />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <XpektorButton
                icon={faDownload}
                content="Spara som PDF"
                onClick={() =>
                  onPdfClick(() => document.getElementById('pdf-wrapper'))
                }
              />
            </div>
          </Module.Nav>
          <div id="pdf-wrapper">
            <Module.KeyFigureSection>
              <Module.KeyFigure
                title="Saldo bankkonton"
                value={liquidityStatus.liquidityReport.bankBalance}
                valueType="currency"
                info="Saldo på bankkonto."
              />
              <Module.KeyFigure
                title="Kommande inbetalningar"
                value={liquidityStatus.liquidityReport.upcomingIncomingPayments}
                valueType="currency"
                info="Bokförda och icke bokförda intäkter som förväntas komma."
              />
              <Module.KeyFigure
                title="Kommande utbetalningar"
                value={liquidityStatus.liquidityReport.upcomingOutgoingPayments}
                valueType="currency"
                info="Bokförda och icke bokförda utgifter som förväntas komma."
              />
              <Module.KeyFigure
                title="Prediktivt kassaflöde"
                value={liquidityStatus.liquidityReport.predictiveCashFlow}
                valueType="currency"
                info="Kommande likvida medel beräknat av kommande bokförda och
              icke bokförda likviditetspåverkande poster."
              />
            </Module.KeyFigureSection>
            <Module.DataSection>
              <Module.DataColumn>
                <DataPanel title="Skatter">
                  <DataPanel.ValueEntry
                    label="Saldo skattekonto"
                    value={liquidityStatus.liquidityReport.taxBalance}
                    valueType="currency"
                    info="Aggregerat saldo på Skattekonto, hämtas från bokfört
                  saldo. Om Xpektor Tech AB är registrerat som ombud
                  hos Skatteverket hämtas saldo direkt därifrån."
                  />
                  <DataPanel.ValueEntry
                    label="Moms att betala"
                    value={liquidityStatus.liquidityReport.vatToPay}
                    valueType="currency"
                    info="Ingående och utgånde moms föregående perioder som
                  påverkar och ingår i prediktivt kassaflöde."
                  />
                  <DataPanel.ValueEntry
                    label="Skatt att betala"
                    value={liquidityStatus.liquidityReport.taxToPay}
                    valueType="currency"
                    info="Skatter från föregående perioder som ska betalas. Saldon
                  hämtas från konton 2510, 2514, 2518, 2710, 2730, och
                  2731. Ingår i det prediktiva kassaflödet."
                  />
                  <DataPanel.ValueEntry
                    label="Anstånd SKV"
                    value={liquidityStatus.liquidityReport.deferment ?? 0}
                    valueType="currency"
                    info="Godkänt anstånd skatter och moms, förfaller inom ett år."
                  />
                </DataPanel>
                <DataPanel title="Att ta hänsyn till" icon={faBell} background>
                  {translatedTriggers.map((data) => {
                    return (
                      <DataPanel.TriggerEntry
                        icon={faExclamationTriangle}
                        name={data.texts.name}
                        info={data.texts.info}
                        key={`${data.id} liquidity`}
                        tooltipId={`${data.id} liquidity`}
                      />
                    );
                  })}
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Övrigt">
                  <DataPanel.ValueEntry
                    label="Övr. kortfristiga skulder fordringar"
                    value={liquidityStatus.liquidityReport.ostli}
                    valueType="currency"
                    info="Övriga kortfristiga fordringar/skulder som förväntas
                  påverka likviditeten framåt. Saldon hämtas från konton
                  1500-1798 (ej 1630, 1510) och 2800-2899 (ej 2852).
                  Påverkar prediktivt kassaflöde."
                  />
                  <DataPanel.ValueEntry
                    label="Presumtiv lön innevarande månad"
                    value={
                      liquidityStatus.liquidityReport.prospectiveSalaryThisMonth
                    }
                    valueType="currency"
                    info="Förväntad löneutgift innevarande månad. Baseras på ett
                  årsgenomsnitt av årets lönekostnader. Påverkar
                  prediktivt kassaflöde."
                  />
                </DataPanel>
                <DataPanel title="Betalningsförmåga">
                  <DataPanel.ValueEntry
                    label="Genomsnittlig betalning kunder"
                    value={
                      liquidityStatus.liquidityReport.averagePaymentCustomers ??
                      'N/A'
                    }
                    valueType={
                      liquidityStatus.liquidityReport.averagePaymentCustomers
                        ? 'dgr över'
                        : ''
                    }
                    info="Kunders genomsnittliga betaltid räknad i antal dagar."
                  />
                  <DataPanel.ValueEntry
                    label="Antal försenade skattebetalningar"
                    value="N/A"
                    valueType=""
                    info="Antal sena inbetalningar till skattekontot, ger upphov
                  till oren revision."
                  />
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Påminnelseavgifter">
                  <DataPanel.ValueEntry
                    label="Intäktsräntor"
                    value={liquidityStatus.liquidityReport.earningInterest}
                    valueType="currency"
                    info="Intäktsräntor genererade av sent betalande kunder."
                  />
                  <DataPanel.ValueEntry
                    label="Kostnadsräntor"
                    value={liquidityStatus.liquidityReport.costInterest}
                    valueType="currency"
                    info="Kostnadsräntor och/eller påminnelseavgifter genererade av
                  sent betalade skulder."
                  />
                  <DataPanel.ValueEntry
                    label="Summa"
                    value={
                      liquidityStatus.liquidityReport.costAndEarningInterestSum
                    }
                    valueType="currency"
                  />
                </DataPanel>
                <DataPanel title="Upplysningar">
                  {liquidityStatus.triggers.includes(46) ? (
                    <>
                      <DataPanel.Subtitle title="Företaget hanterar kontanter" />
                      <DataPanel.ValueEntry
                        label="Kontanter i kassa"
                        value={liquidityStatus.liquidityReport.cash}
                        valueType="currency"
                      />
                    </>
                  ) : (
                    ''
                  )}
                  {liquidityStatus.triggers.includes(47) ? (
                    <>
                      <DataPanel.Subtitle title="Bolaget visar på befarade kundförluster" />
                      <DataPanel.ValueEntry
                        label="Summa befarade kundförluster"
                        value={liquidityStatus.liquidityReport.fearedBadDebt}
                        valueType="currency"
                      />
                    </>
                  ) : (
                    ''
                  )}
                </DataPanel>
              </Module.DataColumn>
            </Module.DataSection>
          </div>
        </>
      )}
    </Module>
  );
}
