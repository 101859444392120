import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BankService from '../../Services/BankService';
import LoadingBar from '../LoadingBar/LoadingBar';
import AuthService from '../../Services/AuthService';

export default function AuthorizeEnableBanking() {
  const history = useHistory();
  const { search } = useLocation();
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const updateClientSession = async () => {
      setLoadingText('Autentiserar...');
      // Values from address
      const code = new URLSearchParams(search).get('code');
      const state = new URLSearchParams(search).get('state');
      const error = new URLSearchParams(search).get('error');

      const stateParams = await AuthService.decode(state);

      const clientId = new URLSearchParams(stateParams).get('clientId');
      const target = new URLSearchParams(stateParams).get('target');
      const externalStateId = new URLSearchParams(stateParams).get('ext');

      let url = '';
      if (error === null) {
        setLoadingText('Hämtar data, detta kan ta ett tag...');
        await BankService.authorizeSession(
          clientId,
          code,
          externalStateId ?? null
        );

        url =
          target === 'external'
            ? `/authorization?action=auth&ext=${externalStateId}`
            : `/settings/clients?action=connectSkv&clientId=${clientId}`;
      } else {
        const errorDescription = new URLSearchParams(search).get(
          'error_description'
        );
        url =
          target === 'external'
            ? `/authorization?action=error&error_type=bank&error_description=${errorDescription}&ext=${externalStateId}`
            : `/companyinfo/${clientId}/equity`;
      }

      history.push(url);
    };
    updateClientSession();
  }, [history, search]);

  return <LoadingBar active status={loadingText} />;
}
