/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */

import { Button, Col, Modal, Row } from 'react-bootstrap';
import ClientService from '../../../../Services/ClientService';

function DeleteModal({ show, handleClose, action, clientToEdit, customerId }) {
  const clickDelete = async () => {
    await ClientService.deleteClient(clientToEdit.id, customerId)
      .then((response) => {
        if (response) {
          window.location = '/settings/clients';
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          alert('Kunde inte ta bort klient');
        }
        if (error.response.status === 403) {
          alert('Du har ej behörighet att ta bort klient');
        }
        if (error.response.status === 500) {
          alert('Något gick fel, försök igen senare');
        }
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0"
    >
      <Modal.Header className="border-0">
        <Modal.Title>Ta bort klient</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <p>
          {`Klienten `}{' '}
          <span>
            <b>{clientToEdit.name}</b>
          </span>{' '}
          {`kommer att raderas. Denna åtgärd kan
          inte ångras.`}
        </p>
        <br />
        <p>Vill du fortsätta?</p>
        <br />
        <Row>
          <Col md={{ span: 3, offset: 6 }}>
            <Button variant="outline-primary" onClick={handleClose}>
              Avbryt
            </Button>
          </Col>
          <Col md={{ span: 3, offset: 0 }}>
            {' '}
            <Button
              variant="danger"
              onClick={() => {
                clickDelete();
              }}
            >
              Ta bort
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;
