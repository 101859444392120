import './AuthLinkModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../../Services/AuthService';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import XpektorForm from '../../../../Components/XpektorForm/XpektorForm';
import ClientService from '../../../../Services/ClientService';

/* eslint-disable react/prop-types */
export default function AuthLinkModal({ authLink, handleClose, handleEdit }) {
  const [authUrl, setAuthUrl] = useState('');

  const [orgNumberErrorText, setOrgNumberErrorText] = useState(null);
  const [orgNumber, setOrgNumber] = useState('');

  const [enableDataFetch, setEnableDataFetch] = useState(false);
  const [dataFetchErrorText, setDataFetchErrorText] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  const [connectingLink, setConnectingLink] = useState(false);
  const [deletingLink, setDeletingLink] = useState(false);

  function ValidateOrgNumber(org) {
    const validLength = org.length === 10;

    if (!validLength) {
      setOrgNumberErrorText('Organisationsnummret måste bestå av 10 siffror');
    } else {
      setOrgNumberErrorText('');
    }

    setEnableDataFetch(validLength === true);
    return validLength;
  }

  function SetOrgNumberInput(newValue) {
    if (newValue.length <= 10) {
      setOrgNumber(newValue);
      ValidateOrgNumber(newValue);
    }
  }

  const getCompanyData = async (event) => {
    event.preventDefault();

    setFetchingData(true);

    await ClientService.getBasicCompanyInfo(orgNumber)
      .then((response) => {
        if (response.data.errorCode === null) {
          setCompanyInfo(response.data);
          setDataFetchErrorText(null);
        } else {
          setCompanyInfo(null);
          setDataFetchErrorText(response.data.errorCode);
        }
      })
      .catch((error) => {
        setCompanyInfo(null);
        if (error.response.status === 404) {
          setDataFetchErrorText(
            'Hittade inget företag med det organisationsnummret.'
          );
        } else {
          setDataFetchErrorText('Kunde inte hämta företaget');
        }
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  useEffect(() => {
    setAuthUrl(
      `${window.location.protocol}//${window.location.host}/authorization?state=${authLink.id}`
    );
  }, [authLink]);

  const copyLink = () => {
    navigator.clipboard.writeText(authUrl);
  };

  const deleteLink = async () => {
    setDeletingLink(true);
    await AuthService.deleteExternalAuthState(authLink.id)
      .then(() => {
        handleEdit();
        handleClose();
      })
      .catch(() => {});
  };

  const connectClientToLink = async () => {
    await ClientService.createClient(
      companyInfo.name,
      companyInfo.organizationNumber
    )
      .then((response) => {
        const updatedAuthState = {
          id: authLink.id,
          targetClient: response.data.id,
        };
        return AuthService.updateExternalAuthState(updatedAuthState);
      })
      .then(() => {
        if (handleEdit != null) {
          handleEdit();
          handleClose();
        }
      })
      .catch(() => {})
      .finally(() => {
        setConnectingLink(false);
      });
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 auth-link-modal"
    >
      <Modal.Header>
        <Modal.Title>{`Länk: ${authLink.targetClient ?? 'N/A'}`}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        {authLink.targetClient === null && (
          <div className="company-info-content">
            <p className="temporary-text">
              Denna länk skapades utan en kopplad organisation och klienten har
              därmed inte möjligheten att hoppa över steget för autentisering
              mot ett bokföringssystem. Åtgärda detta genom att att koppla en
              organisation nedan.
            </p>
            <XpektorForm onSubmit={getCompanyData}>
              <XpektorForm.Input
                label="Org. nummer (10 siffror)"
                type="number"
                placeholder="Ange organisationsnummer"
                value={orgNumber}
                onChange={(e) => {
                  SetOrgNumberInput(e.target.value);
                }}
                infoText={orgNumberErrorText}
              />
              <XpektorForm.Button
                text={fetchingData ? 'Hämtar...' : 'Hämta företag'}
                disabled={!enableDataFetch || fetchingData}
              />
            </XpektorForm>
            {(companyInfo !== null || dataFetchErrorText !== null) && (
              <div
                className={`company-info-display ${
                  dataFetchErrorText != null && 'company-info-error'
                }`}
              >
                <p className="company-name">
                  {dataFetchErrorText ?? companyInfo?.name}
                </p>
                {companyInfo?.organizationNumber != null && (
                  <p className="company-org-number">
                    {companyInfo.organizationNumber}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
        {companyInfo !== null && dataFetchErrorText === null && (
          <XpektorButton
            loading={connectingLink}
            content="Koppla företag till länk"
            onClick={() => {
              connectClientToLink();
            }}
          />
        )}
        <div className="auth-link-display">
          <XpektorButton
            content={
              animatingCopied ? (
                <FontAwesomeIcon className="copied-check-icon" icon={faCheck} />
              ) : (
                'Kopiera'
              )
            }
            onClick={() => {
              copyLink();
              setAnimatingCopied(true);
            }}
          />
          <p className="auth-link-url">{authUrl}</p>
        </div>
        <div className="auth-link-modal-footer">
          <XpektorButton
            loading={deletingLink}
            content="Ta bort"
            onClick={() => {
              deleteLink();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
