import './ToggleButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';

/* eslint-disable react/prop-types */
export default function ToggleButton({ onClick, text, active, count }) {
  return (
    <button
      type="button"
      className={`toggle-button ${active && 'toggle-active'}`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={active ? faSquareCheck : faSquare}
        className="toggle-icon"
      />
      <p>{text}</p>
      {count && <p className="item-count">{count}</p>}
    </button>
  );
}
