import './SystemAdmin.scss';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TabNav from '../../../Components/TabNav/TabNav';

export default function SystemAdmin() {
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();

  const isLocation = (path) => {
    return history.location.pathname.includes(path);
  };

  useEffect(() => {
    const getData = async () => {
      /* const user = await UserService.getCurrentUser();
       setAuthState((state) => ({ ...state, user })); */

      if (isLocation('drift')) {
        setActiveTab('drift');
      }
    };
    getData();
  }, []);

  return (
    <div className="system-admin-wrapper">
      <div className="system-admin-head">
        <p className="system-admin-title">System Admin</p>
      </div>
      <div className="system-admin-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Drift"
            active={activeTab === 'drift'}
            onClick={() => {
              setActiveTab('drift');
              history.push(`/systemadmin/drift`);
            }}
          />
          <TabNav.Tab title="Annat" active={activeTab === 'other'} />
        </TabNav>
      </div>
    </div>
  );
}
