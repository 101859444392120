/* eslint-disable no-alert */
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faEyeSlash,
  faEnvelope,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import './Login.scss';
import XpektorForm from '../../../Components/XpektorForm/XpektorForm';
import XpektorLogo from '../../../Images/Xpektor.png';
import AuthContext from '../../../context/auth-context';
import AuthService from '../../../Services/AuthService';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const history = useHistory();
  const [, setAuthState] = useContext(AuthContext);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setAuthState((state) => ({ ...state, user }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePasswordVisibility = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  const loginAPI = async () => {
    try {
      const response = await AuthService.login(email, password);
      if (response) {
        setAuthState((state) => ({ ...state, user: response }));
        history.push('/dashboard');
      }
    } catch (error) {
      if (error.response.status === 400) {
        setErrorText('Fel användarnamn eller lösenord.');
      } else if (error.response.status === 500) {
        setErrorText('Något gick fel försök igen om en stund');
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      localStorage.clear();
      await loginAPI();
    } catch (e) {
      alert(e.message);
    }
  };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  return (
    <div className="login-wrapper">
      <div className="left-bg">
        <div className="left-content-container">
          <div className="left-header">
            <div className="logo">
              <img src={XpektorLogo} alt="XpektorLogo" />
              <a href="/">Xpektor</a>
            </div>
            <div className="login-title">
              <p>Logga in</p>
            </div>
          </div>
          <div className="login-form-container">
            <XpektorForm onSubmit={handleSubmit}>
              <XpektorForm.Input
                label="Email"
                type="text"
                placeholder="john-smith@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                icon={faEnvelope}
              />
              <XpektorForm.Input
                label="Lösenord"
                type={passwordFieldType}
                placeholder="Skriv lösenord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                icon={passwordFieldType === 'password' ? faEyeSlash : faEye}
                onIconClick={() => {
                  togglePasswordVisibility();
                }}
                infoText={errorText}
              />
              <XpektorForm.Button text="Logga in" disabled={!validateForm()} />
            </XpektorForm>
          </div>
          <div className="left-footer-container">
            <div className="contact">
              <p>
                Vill du testa Xpektor?{' '}
                <a
                  href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Boka demo
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-bg">
        <div className="right-content-container">
          <div className="right-header">
            <div className="salespitch-1">
              <p>Affärskritiska insikter i realtid</p>
            </div>
            <div className="salespitch-2">
              <p>Automatiserad rådgivning</p>
            </div>
          </div>
          <div className="screenshot" />
        </div>
      </div>
    </div>
  );
}
