/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import './ConnectAccountingModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import AuthService from '../../../Services/AuthService';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
export default function ConnectAccountingModal({
  handleClose,
  action,
  client,
  authLevel,
}) {
  const [createClient] = useState({
    returnLocation: 2, // Create client
    targetId: null,
    action: 0, // Create client
  });

  const [editClient] = useState({
    returnLocation: 1, // Update client
    targetId: client.id,
    action: 1, // Update client
  });

  const redirectToFortnox = async () => {
    if (action === 'createClient') {
      await AuthService.getAuthUrlFortnox(
        createClient.returnLocation,
        createClient.targetId,
        createClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
    if (action === 'updateClient') {
      await AuthService.getAuthUrlFortnox(
        editClient.returnLocation,
        editClient.targetId,
        editClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
  };
  const redirectToVisma = async () => {
    if (action === 'createClient') {
      await AuthService.getAuthUrlVisma(
        createClient.returnLocation,
        createClient.targetId,
        createClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
    if (action === 'updateClient') {
      await AuthService.getAuthUrlVisma(
        editClient.returnLocation,
        editClient.targetId,
        editClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
  };
  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="connect-accounting-modal"
    >
      <Modal.Header className="border-0">
        <Modal.Title className="modal-title">Koppla klient</Modal.Title>

        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <p className="description">Välj bokföringssystem.</p>
        <div className="integration-options">
          <div className="integration-partner">
            <img className="partner-logo" src={FortnoxLogo} alt="Fortnox" />
            <XpektorButton
              content="Till Fortnox"
              onClick={() => redirectToFortnox()}
            />
          </div>
          <div className="integration-partner">
            <img className="partner-logo" src={VismaLogo} alt="Visma" />
            <XpektorButton
              content="Till Visma"
              onClick={() => redirectToVisma()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
