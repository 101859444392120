import api from './ApiMiddleware';
import TokenService from './TokenService';

const getCurrentUser = () => {
  return TokenService.getLocalUser();
};

const validatePw = async (password) => {
  const username = getCurrentUser().email;

  return api
    .post(`/api/v1/identity/validate`, {
      username,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      }

      return false;
    });
};

const changePw = (oldPw, newPw) => {
  return api.patch(`/api/v1/user/password`, {
    oldPassword: oldPw,
    newPassword: newPw,
  });
};

const adminChangePw = (id, pw) => {
  return api.patch(`/api/v1/user/${id}/password`, {
    password: pw,
  });
};

const CreateUser = (user) => {
  return api.post(`/api/v1/user`, {
    customerId: user.customerId,
    email: user.email,
    password: user.password,
    name: user.name,
    role: user.role,
  });
};

const UpdateUser = (user) => {
  return api.put(`/api/v1/user/${user.id}`, {
    customerId: user.customerId,
    email: user.email,
    name: user.name,
    role: user.role,
  });
};

const DeleteUser = (id) => {
  return api.delete(`/api/v1/user/${id}`);
};

const UserService = {
  getCurrentUser,
  validatePw,
  changePw,
  adminChangePw,
  CreateUser,
  UpdateUser,
  DeleteUser,
};

export default UserService;
