import api from './ApiMiddleware';

const getOmbudForClient = (clientId) => {
  return api.get(`/api/v1/skv/${clientId}/ombud`);
};

const isXpektorOmbudForClient = (clientId) => {
  return api.get(`/api/v1/skv/${clientId}/ombud/xpektor`);
};

const getDeepLinkSKV = (clientId) => {
  return api.get(`/api/v1/skv/${clientId}/authurl`);
};

const SKVService = {
  getOmbudForClient,
  isXpektorOmbudForClient,
  getDeepLinkSKV,
};

export default SKVService;
