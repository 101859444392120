import './TutorialModal.scss';
import { Button, Modal } from 'react-bootstrap';
import FortnoxImg1 from '../../../Images/Tutorials/Fortnox/Fortnox_integration_1.png';
import FortnoxImg2 from '../../../Images/Tutorials/Fortnox/Fortnox_integration_2.png';
import FortnoxImg3 from '../../../Images/Tutorials/Fortnox/Fortnox_integration_3.png';
import FortnoxImg4 from '../../../Images/Tutorials/Fortnox/Fortnox_integration_4.png';
import VismaImg1 from '../../../Images/Tutorials/Visma/Visma_integration_1.png';
import VismaImg2 from '../../../Images/Tutorials/Visma/Visma_integration_2.png';
import VismaImg3 from '../../../Images/Tutorials/Visma/Visma_integration_3.png';
import VismaImg4 from '../../../Images/Tutorials/Visma/Visma_integration_4.png';
import VismaImg5 from '../../../Images/Tutorials/Visma/Visma_integration_5.png';
import VismaImg6 from '../../../Images/Tutorials/Visma/Visma_integration_6.png';
/* eslint-disable react/prop-types */

export default function AccountingTutorialModal({ partnerId, handleClose }) {
  const fortnoxImages = [
    { img: FortnoxImg1, index: 0 },
    { img: FortnoxImg2, index: 1 },
    { img: FortnoxImg3, index: 2 },
    { img: FortnoxImg4, index: 3 },
  ];

  const vismaImages = [
    { img: VismaImg1, index: 0 },
    { img: VismaImg2, index: 1 },
    { img: VismaImg3, index: 2 },
    { img: VismaImg4, index: 3 },
    { img: VismaImg5, index: 3 },
    { img: VismaImg6, index: 3 },
  ];

  const selectedImages =
    partnerId === 0 ? fortnoxImages : partnerId === 1 ? vismaImages : [];

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 tutorial-wrapper"
    >
      <Modal.Header>
        <Modal.Title>
          {partnerId === 0 ? 'Fortnox integration' : 'Visma integration'}
        </Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="tutorial-modal-content">
          {selectedImages.map((data) => {
            return (
              <img
                className="tutorial-image"
                src={data.img}
                alt="TutorialImage"
                key={data.index}
              />
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}
