/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import './ConnectSkvModal.scss';
import { Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import SKVService from '../../../Services/SKVService';

export default function ConnectSkvModal({ handleClose, clientId }) {
  const [authLink, setAuthLink] = useState('');
  useEffect(() => {
    const getAuthLink = async () => {
      await SKVService.getDeepLinkSKV(clientId)
        .then((response) => {
          if (response.data) {
            setAuthLink(response.data);
          } else {
            alert('Något gick fel försök igen om en stund');
          }
        })
        .catch(() => {
          alert('Något gick fel försök igen om en stund');
        });
    };
    getAuthLink();
  }, [clientId]);

  const copyLink = () => {
    navigator.clipboard.writeText(authLink);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="skv-link-modal-wrapper"
    >
      <Modal.Header>
        <Modal.Title>Autentisering av ombud hos Skatteverket</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="skv-link-description">
          <p>
            Klienten behöver godkänna Xpektor som ombud för att vi ska kunna
            hämta skattesaldo.
          </p>
        </div>
        <div className="skv-link-modal-button-container">
          <XpektorButton
            content="Kopiera länk"
            onClick={() => {
              copyLink();
            }}
          />
          <a
            href={authLink}
            target="_blank"
            rel="noopener noreferrer"
            className="skatteverket-link"
          >
            <XpektorButton content="Till skatteverket" />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}
