import './LoadingBar.scss';
/* eslint-disable react/prop-types */

export default function LoadingBar(props) {
  const { active } = props;
  const { status } = props;
  const { relative } = props;
  return (
    active && (
      <div
        className={`loading-wrapper ${
          relative ? 'loading-relative' : 'loading-absolute'
        }`}
      >
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
        <p className="status-text">{status}</p>
      </div>
    )
  );
}
