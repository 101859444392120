/* eslint-disable react/prop-types */
import './DatePicker.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import XpektorButton from '../XpektorButton/XpektorButton';
import Dropdown from '../Dropdown/Dropdown';

function DayButton({ day, selected, onClick, disabled, placeholder }) {
  return (
    <button
      type="button"
      onClick={(value) => onClick(value)}
      disabled={disabled || placeholder}
      className={`calendar-day-button ${
        selected
          ? 'selected'
          : placeholder
          ? 'placeholder'
          : disabled
          ? 'disabled'
          : ''
      }`}
    >
      {day}
    </button>
  );
}

export default function DatePicker({ title, date, setDate, minDate, maxDate }) {
  const [dayArray, setDayArray] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');

  const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [yearDropdownOpen, setYearDropdownOpen] = useState(false);

  const years = [];
  for (let i = Moment(minDate).year(); i <= Moment(maxDate).year(); i += 1) {
    years.push(i);
  }

  useEffect(() => {
    const newArray = [];
    const days = Moment(date).daysInMonth();
    const startDay = Moment(date).set('date', 1).day();
    for (let i = -(startDay - 1); i < days; i += 1) {
      newArray.push(i + 1);
    }
    setDayArray(newArray);

    setFormattedDate(Moment(date).format('YYYY-MM-DD'));
  }, [date]);

  const weekDays = [
    { id: '0', letter: 'M' },
    { id: '1', letter: 'T' },
    { id: '2', letter: 'O' },
    { id: '3', letter: 'T' },
    { id: '4', letter: 'F' },
    { id: '5', letter: 'L' },
    { id: '6', letter: 'S' },
  ];

  const months = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ];

  const setDateClamped = (newDate) => {
    if (newDate > maxDate) {
      setDate(Moment(maxDate));
    } else if (newDate < minDate) {
      setDate(Moment(minDate));
    } else {
      setDate(newDate);
    }
  };

  return (
    <div className="xpektor-date-picker">
      <div className="date-picker-header">
        <p className="title">{title}</p>
        <p className="date">{formattedDate}</p>
      </div>
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <button
            type="button"
            className="month-arrow"
            title="arrow"
            disabled={
              Moment(minDate).year() === Moment(date).year() &&
              Moment(minDate).month() === Moment(date).month()
            }
            onClick={() => setDateClamped(Moment(date).subtract(1, 'months'))}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <Dropdown
            title={months[Moment(date).month()]}
            open={monthDropdownOpen}
            onClick={() => setMonthDropdownOpen(!monthDropdownOpen)}
          >
            {months.map((month) => {
              return (Moment(date).year() === Moment(minDate).year() &&
                months.indexOf(month) < Moment(minDate).month()) ||
                (Moment(date).year() === Moment(maxDate).year() &&
                  months.indexOf(month) > Moment(maxDate).month()) ? (
                ''
              ) : (
                <Dropdown.Item
                  key={month}
                  content={month}
                  onClick={() => {
                    setDateClamped(
                      Moment(date).set('month', months.indexOf(month))
                    );
                    setMonthDropdownOpen(false);
                  }}
                />
              );
            })}
          </Dropdown>
          <Dropdown
            title={Moment(date).format('YYYY')}
            open={yearDropdownOpen}
            onClick={() => setYearDropdownOpen(!yearDropdownOpen)}
          >
            {years.map((year) => {
              return (
                <Dropdown.Item
                  key={year}
                  content={year}
                  onClick={() => {
                    setDateClamped(Moment(date).set('year', year));
                    setYearDropdownOpen(false);
                  }}
                />
              );
            })}
          </Dropdown>
          <button
            type="button"
            className="month-arrow"
            title="arrow"
            disabled={
              Moment(maxDate).year() === Moment(date).year() &&
              Moment(maxDate).month() === Moment(date).month()
            }
            onClick={() => setDateClamped(Moment(date).add(1, 'months'))}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
        <div className="weekday-row">
          {weekDays.map((day) => {
            return <p key={day.id}>{day.letter}</p>;
          })}
        </div>
        <div className="calendar-grid">
          {dayArray.map((day) => {
            return (
              <DayButton
                day={day}
                key={day}
                placeholder={day < 1}
                disabled={
                  (day >= 1 && Moment(date).set('date', day) < minDate) ||
                  Moment(date).set('date', day) > maxDate
                }
                selected={date.date() === day}
                onClick={() => setDate(Moment(date).set('date', day))}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function DateRangePicker({
  fromDate,
  toDate,
  minDate,
  maxDate,
  setFromDate,
  setToDate,
  onSubmit,
}) {
  return (
    <div className="xpektor-date-range-picker">
      <div className="calendars">
        <DatePicker
          title="Från"
          date={fromDate}
          setDate={(value) => setFromDate(value)}
          minDate={minDate}
          maxDate={maxDate}
        />
        <DatePicker
          title="Till"
          date={toDate}
          setDate={(value) => setToDate(value)}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="sub-nav">
        <XpektorButton content="Hämta" onClick={onSubmit} />
      </div>
    </div>
  );
}
