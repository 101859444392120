/* eslint-disable react/prop-types */
import Moment from 'moment';
import { useState, useEffect } from 'react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import Module from './Module';
import TransactionTable from './Transactions/TransactionTable/TransactionTable';
import BankService from '../../../../Services/BankService';
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import { DateRangePicker } from '../../../../Components/DatePicker/DatePicker';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import ToggleButton from './Transactions/ToggleButton/ToggleButton';
import TransactionDetails from './Transactions/TransactionDetails/TransactionDetails';

export default function Accounts({ client }) {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [selectedAccount, setSelectedAccount] = useState('all');
  const [accountData, setAccountData] = useState([]);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedBalanceAvailable, setSelectedBalanceAvailable] = useState(0);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [targetTransaction, setTargetTransaction] = useState({});

  const [transactionDates, setTransactionDates] = useState([]);
  const [transactionInsights, setTransactionInsights] = useState([]);
  const [categories, setCategories] = useState([]);

  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [accountDropdownText, setAccountDropdownText] = useState('Alla konton');
  const [calendarDropdownOpen, setCalendarDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [fetchingAccountData, setFetchingAccountData] = useState(false);

  function SelectAccountTransactionsFrom(account, data) {
    const newArray = [];
    for (let i = 0; i < data.length; i += 1) {
      if (account === 'all' || account === data[i].accountId) {
        for (let j = 0; j < data[i].transactions.length; j += 1) {
          newArray.push(data[i].transactions[j]);
        }
      }
    }
    setSelectedTransactions(newArray);
    return newArray;
  }

  function SelectBalancesFrom(account, data) {
    let balance = 0;

    for (let i = 0; i < data.length; i += 1) {
      if (account === 'all' || data[i].accountId === account) {
        balance += Number(data[i].balanceAvailable.amount);
      }
    }
    setSelectedBalanceAvailable(balance);
  }

  function GenerateInsightsFrom(transactions) {
    const newInsightsArray = [];

    if (transactions === null || transactions.length === 0) {
      setTransactionInsights(newInsightsArray);
      return;
    }

    let paymentsIn = 0;
    let paymentsOut = 0;
    let salaryPayments = 0;
    let taxPayments = 0;
    let gamblingPayments = 0;

    for (let i = 0; i < transactions.length; i += 1) {
      if (transactions[i].creditDebitIndicator === 'DBIT') {
        paymentsOut += 1;

        if (transactions[i].name === 'Skatteverket') {
          taxPayments += 1;
        }
        if (transactions[i].name === 'Salary') {
          salaryPayments += 1;
        }
        if (
          transactions[i].industries.some((industry) => industry === 'Gambling')
        ) {
          gamblingPayments += 1;
        }
      } else {
        paymentsIn += 1;
      }
    }

    const percentagePaymentsIn = (paymentsIn / transactions.length) * 100;
    newInsightsArray.push(
      `${percentagePaymentsIn.toFixed(0)}% av transaktionerna är inbetalningar.`
    );

    const percentagePaymentsOut = (paymentsOut / transactions.length) * 100;
    newInsightsArray.push(
      `${percentagePaymentsOut.toFixed(
        0
      )}% av transaktionerna är utbetalningar.`
    );

    if (salaryPayments > 0 && taxPayments === 0) {
      newInsightsArray.push(
        'Under vald period finns det lön men ingen betalning till skatteverket.'
      );
    }

    if (gamblingPayments > 0) {
      newInsightsArray.push('Finns spel.');
    }

    setTransactionInsights(newInsightsArray);
  }

  function FindUniqueDates(transactions) {
    const uniqueDates = [];
    for (let i = 0; i < transactions.length; i += 1) {
      const date = Moment(
        transactions[i].valueDate ?? transactions[i].bookingDate
      ).format('YYYY-MM-DD');
      if (uniqueDates.indexOf(date) === -1) {
        uniqueDates.push(date);
      }
    }
    setTransactionDates(uniqueDates);
  }

  function FindCategories(transactions) {
    const uniqueCategories = [];
    for (let i = 0; i < transactions.length; i += 1) {
      transactions[i].industries.forEach((industry) => {
        if (!uniqueCategories.some((category) => category.name === industry)) {
          uniqueCategories.push({ name: industry, occurences: 1 });
        } else {
          for (let j = 0; j < uniqueCategories.length; j += 1) {
            if (uniqueCategories[j].name === industry) {
              uniqueCategories[j].occurences += 1;
              break;
            }
          }
        }
      });
    }
    setCategories(uniqueCategories);
  }

  function SelectAccount(account) {
    setSelectedAccount(account);
    const transactions = SelectAccountTransactionsFrom(
      account,
      accountTransactions
    );
    SelectBalancesFrom(account, accountData);
    FindUniqueDates(transactions);
    FindCategories(transactions);
    GenerateInsightsFrom(transactions);
  }

  const getTransactions = async () => {
    setIsLoading(true);
    setLoadingText('Hämtar transaktioner...');

    await BankService.getTransactions(
      client.id,
      Moment(fromDate).format('YYYY-MM-DD'),
      Moment(toDate).format('YYYY-MM-DD')
    )
      .then((response) => {
        setAccountTransactions(response.data);
        const transactions = SelectAccountTransactionsFrom(
          selectedAccount,
          response.data
        );
        FindUniqueDates(transactions);
        FindCategories(transactions);
        GenerateInsightsFrom(transactions);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setMaxDate(Moment.utc().local());
    setMinDate(Moment.utc().local().subtract(1, 'years'));
  }, []);

  // When client has loaded in companyinfo
  useEffect(() => {
    const getData = async () => {
      const newFromDate = Moment.utc().local().subtract(1, 'months');
      const newToDate = Moment.utc().local();
      setFromDate(newFromDate);
      setToDate(newToDate);

      setFetchingAccountData(true);

      await BankService.getBankAccounts(client.id)
        .then((response) => {
          setAccountData(response.data);
          SelectBalancesFrom('all', response.data);
        })
        .catch(() => {
          // reload client to get new connection status to check if the
          // failure is due to the bank connection having been broken.
        })
        .finally(() => {
          setFetchingAccountData(false);
        });

      await BankService.getTransactions(
        client.id,
        Moment(newFromDate).format('YYYY-MM-DD'),
        Moment(newToDate).format('YYYY-MM-DD')
      ).then((response) => {
        setAccountTransactions(response.data);
        const transactions = SelectAccountTransactionsFrom(
          'all',
          response.data
        );
        FindUniqueDates(transactions);
        FindCategories(transactions);
        GenerateInsightsFrom(transactions);
      });
    };
    if (client.bankConnectionActive) {
      getData();
    }
  }, [client]);

  function CloseAccountDropdownIfOpen() {
    if (accountDropdownOpen) {
      setAccountDropdownOpen(false);
    }
  }

  function OnSetFromDate(newDate) {
    if (newDate > toDate) setToDate(newDate);
    setFromDate(newDate);
  }

  function OnSetToDate(newDate) {
    if (newDate < fromDate) setFromDate(newDate);
    setToDate(newDate);
  }

  const sortedDates = transactionDates.sort(
    (a, b) => new Date(b) - new Date(a)
  );

  // FILTERS
  const [filters, setFilters] = useState([]);

  function AddFilter(filter) {
    setFilters((oldArray) => [...oldArray, filter]);
  }
  function RemoveFilter(filter) {
    setFilters(
      filters.filter((item) => {
        return item !== filter;
      })
    );
  }
  function ContainsFilter(filter) {
    return filters.indexOf(filter) !== -1;
  }

  const filteredTransactions =
    filters.length > 0
      ? selectedTransactions.filter((transaction) =>
          transaction.industries.some((industry) => ContainsFilter(industry))
        )
      : selectedTransactions;

  function ToggleFilter(filter) {
    if (ContainsFilter(filter)) {
      RemoveFilter(filter);
    } else {
      AddFilter(filter);
    }
  }

  return (
    <Module>
      {!client.bankConnectionActive ? (
        <p className="module-empty-text">Ingen bank kopplad.</p>
      ) : fetchingAccountData ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : Object.keys(accountData).length === 0 ? (
        <p className="module-empty-text">Kunde inte hämta konto data.</p>
      ) : (
        <>
          <Module.Nav>
            <Dropdown
              title={accountDropdownText}
              open={accountDropdownOpen}
              onClick={() => {
                setAccountDropdownOpen(!accountDropdownOpen);
              }}
            >
              <Dropdown.Item
                content="Alla konton"
                onClick={() => {
                  setAccountDropdownText('Alla konton');
                  SelectAccount('all');
                  CloseAccountDropdownIfOpen();
                }}
              />
              {accountData.map((data) => {
                return (
                  <Dropdown.Item
                    content={data.details.name}
                    onClick={() => {
                      setAccountDropdownText(
                        `${data.details.name ?? 'Namnlöst konto'} ${
                          data.details.details ?? ''
                        }`
                      );
                      SelectAccount(data.accountId);
                      CloseAccountDropdownIfOpen();
                    }}
                    key={data.accountId}
                  />
                );
              })}
            </Dropdown>
            <Dropdown
              title={`Från ${Moment(fromDate).format(
                'YYYY-MM-DD'
              )} till ${Moment(toDate).format('YYYY-MM-DD')}`}
              titleIcon={faCalendar}
              open={calendarDropdownOpen}
              onClick={() => {
                setCalendarDropdownOpen(!calendarDropdownOpen);
              }}
              right
            >
              <DateRangePicker
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={(value) => {
                  OnSetFromDate(value);
                }}
                setToDate={(value) => {
                  OnSetToDate(value);
                }}
                minDate={minDate}
                maxDate={maxDate}
                onSubmit={() => {
                  getTransactions();
                  setCalendarDropdownOpen(false);
                }}
              />
            </Dropdown>
          </Module.Nav>
          <Module.KeyFigureSectionAccounts>
            <Module.KeyFigure
              title="Saldo"
              value={selectedBalanceAvailable}
              valueType="currency"
            />
          </Module.KeyFigureSectionAccounts>
          <LoadingBar active={isLoading} status={loadingText} />
          <Module.TransactionContent>
            <DataPanel title="Filtrera" scroll>
              {/* <ToggleButton
                text="Inbetalningar"
                active={ContainsFilter('Inbetalningar')}
                onClick={() => ToggleFilter('Inbetalningar')}
                count={0}
              />
              <ToggleButton
                text="Utbetalningar"
                active={ContainsFilter('Utbetalningar')}
                onClick={() => ToggleFilter('Utbetalningar')}
                count={0}
              /> */}
              {categories.map((category) => {
                return (
                  <ToggleButton
                    text={category.name}
                    key={category.name}
                    active={ContainsFilter(category.name)}
                    onClick={() => ToggleFilter(category.name)}
                    count={category.occurences}
                  />
                );
              })}
            </DataPanel>
            <TransactionTable>
              {sortedDates.map((date) => {
                return (
                  <div key={date}>
                    <p className="transaction-date">{date}</p>
                    {filteredTransactions.map((data) => {
                      return (
                        Moment(data.valueDate ?? data.bookingDate).format(
                          'YYYY-MM-DD'
                        ) === date && (
                          <TransactionTable.Item
                            transaction={data}
                            key={data.id}
                            onClick={() => {
                              setTargetTransaction(data);
                              setShowTransactionDetails(true);
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                );
              })}
            </TransactionTable>
            <DataPanel title="Insikter">
              {transactionInsights.map((data) => {
                return <DataPanel.TriggerEntry name={data} key={data} />;
              })}
            </DataPanel>
          </Module.TransactionContent>
        </>
      )}
      {showTransactionDetails && (
        <TransactionDetails
          transaction={targetTransaction}
          handleClose={() => setShowTransactionDetails(false)}
        />
      )}
    </Module>
  );
}
