/* eslint-disable react/prop-types */
import './CompanyTable.scss';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faExclamationTriangle,
  faCircleQuestion,
  faLinkSlash,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

function FormatValue(value, valueSign, decimalScale) {
  return (
    <div className="currency-format-container">
      <NumberFormat
        className={`value ${value < 0 ? 'error' : ''}`}
        value={value}
        displayType="text"
        decimalScale={decimalScale}
        thousandSeparator={' '}
      />
      <p className="value-sign">{valueSign}</p>
    </div>
  );
}

function TableHeaderItem({ label, onClick, orderIcon }) {
  return (
    <th>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
      </button>
      <FontAwesomeIcon icon={orderIcon ?? faSort} />
    </th>
  );
}

export default function CompanyTable({
  children,
  order,
  sortByName,
  sortByStatus,
  statusType,
}) {
  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <table className="company-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Klientnamn"
            onClick={() => {
              sortByName();
              OnSort();
              setCurrentOrdered('name');
            }}
            orderIcon={currentOrdered === 'name' ? arrowIcon : null}
          />
          {statusType === 'management' ? (
            <>
              <th>Soliditet</th>
              <th>Skuldsättningsgrad</th>
              <th>Bruttomarginal</th>
              <th>Rörelsemarginal</th>
              <th>Likviditet</th>
            </>
          ) : statusType === 'liquidity' ? (
            <>
              <th>Saldo bankkoton</th>
              <th>Kommande inbetalningar</th>
              <th>Kommande utbetalningar</th>
              <th>Prediktivt kassaflöde</th>
            </>
          ) : (
            <>
              <th>Aktiekapital</th>
              <th>Bokfört resultat</th>
              <th>Prediktivt resultat</th>
              <th>Kapital att förbruka</th>
            </>
          )}

          <TableHeaderItem
            label="Status"
            onClick={() => {
              sortByStatus();
              OnSort();
              setCurrentOrdered('status');
            }}
            orderIcon={currentOrdered === 'status' ? arrowIcon : null}
          />
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

CompanyTable.Item = function Item({ client, status, statusType, onClick }) {
  const renderStatusIcon = () => {
    return (
      <td>
        {status.clientStatus === 0 ? (
          <FontAwesomeIcon icon={faCircleCheck} className="ok" />
        ) : status.clientStatus === 1 ? (
          <FontAwesomeIcon icon={faExclamationTriangle} className="warning" />
        ) : (
          <FontAwesomeIcon icon={faExclamationTriangle} className="error" />
        )}
      </td>
    );
  };

  const renderEquityValues = () => {
    const totalEquity = status.financialReport
      ? (status.financialReport.equity +
          status.financialReport.shareCapital / 2 +
          status.financialReport.result) *
        -1
      : 0;
    const predictiveResult = status.financialReport
      ? status.financialReport.calculatedResultAfterTax
      : 0;

    return (
      <>
        <td>{FormatValue(status.financialReport.shareCapital, 'kr', 0)}</td>
        <td>{FormatValue(status.financialReport.result * -1, 'kr', 0)}</td>
        <td>{FormatValue(predictiveResult * -1, 'kr', 0)}</td>
        <td>{FormatValue(totalEquity, 'kr', 0)}</td>
      </>
    );
  };

  const renderLiquidityValues = () => {
    return (
      <>
        <td>{FormatValue(status.liquidityReport.bankBalance, 'kr', 0)}</td>
        <td>
          {FormatValue(
            status.liquidityReport.upcomingIncomingPayments,
            'kr',
            0
          )}
        </td>
        <td>
          {FormatValue(
            status.liquidityReport.upcomingOutgoingPayments,
            'kr',
            0
          )}
        </td>
        <td>
          {FormatValue(status.liquidityReport.predictiveCashFlow, 'kr', 0)}
        </td>
      </>
    );
  };

  const renderManagementValues = () => {
    return (
      <>
        <td>
          {FormatValue(status.managementReport.solidityPercentage, '%', 0)}
        </td>
        <td>{FormatValue(status.managementReport.debtPercentage, '%', 0)}</td>
        <td>
          {FormatValue(status.managementReport.grossProfitMargin, '%', 0)}
        </td>
        <td>{FormatValue(status.managementReport.operatingMargin, '%', 0)}</td>
        <td>{FormatValue(status.managementReport.liquidity, '%', 0)}</td>
      </>
    );
  };

  const renderData = () => {
    return (
      <>
        {statusType === 'equity'
          ? renderEquityValues()
          : statusType === 'liquidity'
          ? renderLiquidityValues()
          : statusType === 'management'
          ? renderManagementValues()
          : ''}
        {renderStatusIcon()}
      </>
    );
  };

  const renderDataMissing = () => (
    <>
      {statusType === 'equity' ? (
        <td className="table-entry-fill">Finns ingen data för Eget kapital.</td>
      ) : statusType === 'liquidity' ? (
        <td className="table-entry-fill">Finns ingen data för Likviditet.</td>
      ) : statusType === 'management' ? (
        <td className="table-entry-fill">Finns ingen data för Förvaltning.</td>
      ) : (
        ''
      )}
      <td>
        <FontAwesomeIcon icon={faCircleQuestion} className="missing-icon" />
      </td>
    </>
  );

  const renderBrokenLink = () => (
    <>
      <td className="table-entry-fill">Inget bokföringssystem kopplat.</td>
      <td>
        <FontAwesomeIcon icon={faLinkSlash} className="error" />
      </td>
    </>
  );

  return (
    <tr className="company-item-wrapper" onClick={onClick}>
      <td>{client.name}</td>
      {client.externalConnectionActive === false
        ? renderBrokenLink()
        : status !== null
        ? renderData()
        : renderDataMissing()}
    </tr>
  );
};
