/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-alert */
import './ExternalAuthorization.scss';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import BankService from '../../Services/BankService';
import ClientService from '../../Services/ClientService';
import CustomerService from '../../Services/CustomerService';
import SKVService from '../../Services/SKVService';
import AuthService from '../../Services/AuthService';
import XpektorLogo from '../../Images/Xpektor.png';
import XpektorModal from '../../Components/XpektorModal/XpektorModal';
import XpektorButton from '../../Components/XpektorButton/XpektorButton';
import LoadingBar from '../../Components/LoadingBar/LoadingBar';
import AccountingAuthStep from './AuthStates/AccountingAuthStep';
import BankAuthStep from './AuthStates/BankAuthStep';
import SkvAuthStep from './AuthStates/SkvAuthStep';
import AccountingTutorialModal from './Tutorials/AccountingTutorialModal';
import { AuthStepDisplay, AuthStepIcon } from './ExternalAuthComponents';

export default function ExternalAuthorization() {
  const [authState, setAuthState] = useState('');
  const [loadingText, setLoadingText] = useState('Vänligen vänta...');

  const [errorText, setErrorText] = useState('');
  const [errorDetails, setErrorDetails] = useState('');

  const [externalAuthState, setExternalAuthState] = useState(null);

  const { search } = useLocation();

  const [showFortnoxTutorial, setShowFortnoxTutorial] = useState(false);
  const [logo, setLogo] = useState('');

  const authCode = new URLSearchParams(search).get('authCode');
  const partnerId = new URLSearchParams(search).get('partnerId');
  const action = new URLSearchParams(search).get('action');
  const state = new URLSearchParams(search).get('state');
  const ext = new URLSearchParams(search).get('ext');
  const errorType = new URLSearchParams(search).get('errorType');

  // Bank stuff
  const [banks, setBanks] = useState([]);

  const [pollingOmbud, setPollingOmbud] = useState(false);
  const [count, setCount] = useState(0);
  const pollOmbud = async () => {
    setCount(count + 1);
    await SKVService.isXpektorOmbudForClient(
      externalAuthState.targetClient
    ).then((response) => {
      if (response.data === true) {
        AuthService.getExternalAuthState(ext)
          .then((authStateResponse) => {
            const updateAuthStateRequest = {
              id: authStateResponse.id,
              targetClient: authStateResponse.targetClient,
            };
            AuthService.updateExternalAuthState(updateAuthStateRequest)
              .then((updatedResponse) => {
                setExternalAuthState(updatedResponse.data);
                setAuthState('completed');
              })
              .catch(() => {})
              .finally(() => {
                setPollingOmbud(false);
              });
          })
          .catch(() => {});
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => pollingOmbud && pollOmbud(), 3000);
    return () => clearTimeout(timer);
  }, [count, pollingOmbud]);

  async function GetBanks() {
    await BankService.getBanks('SE')
      .then((response) => {
        setBanks(response.data);
        return true;
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setErrorText('Oops, något gick fel.');
        }
        if (error.response.status === 404) {
          setErrorText('Kunde inte hämta några banker');
        }
        setAuthState('error');
      });
    return false;
  }

  async function OnExternalAuthStateFetch(extAuthState) {
    if (extAuthState.accountingConnected === false) {
      setAuthState('bokföring');
    } else if (extAuthState.bankConnected === false) {
      await GetBanks()
        .then(() => setAuthState('bank'))
        .catch(() => {});
    } else if (extAuthState.skvConnected === false) {
      setAuthState('skatteverket');
    } else {
      setAuthState('completed');
    }
  }

  useEffect(() => {
    const decodeState = async () => {
      if (state) {
        const response = await AuthService.decodeExternal(state);
        if (response != null) {
          await OnExternalAuthStateFetch(response);
          setExternalAuthState(response);
        } else {
          window.location = `/authorization?action=unauthorized`;
        }
      }
    };
    decodeState();
  }, [state]);

  useEffect(() => {
    const getData = async () => {
      CustomerService.getOnboardingInfo()
        .then((response) => {
          setLogo(response.data.logo);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('Hittar inte kunden');
          }
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
        });
    };
    getData();
  }, []);

  useEffect(() => {
    const translateAuthenticationError = (error) => {
      switch (error) {
        case 'Invoices':
          return 'Kundfakturor';
        case 'SupplierInvoices':
          return 'Leverantörsfakturor';
        case 'Vouchers':
          return 'Verifikat';
        default:
          return error;
      }
    };

    const onAction = async () => {
      setAuthState('loading');

      if (action === 'auth') {
        await AuthService.getExternalAuthState(ext)
          .then((response) => {
            OnExternalAuthStateFetch(response.data);
            setExternalAuthState(response.data);
          })
          .catch(() => {});
      } else if (action === 'createClient') {
        // Land here after being redirected back from accounting auth
        await ClientService.authenticateNewClient(
          authCode,
          partnerId !== null ? Number(partnerId) : null,
          null,
          ext
        )
          .then(() => {
            window.location = `/authorization?action=auth&ext=${ext}`;
          })
          .catch((error) => {
            if (error.response.status === 406) {
              setErrorText(`Du har inte behörighet att autentisera den nya klienten. Anledning: Du saknar licens för 
                ${translateAuthenticationError(error.response.data.message)}`);
            }
            setErrorDetails(
              'Kontrollera att du har alla behörigheter i bokföringssystemet.'
            );
            setAuthState('error');
          });
      } else if (action === 'completed') {
        setAuthState('completed');
      } else if (action === 'unauthorized') {
        setAuthState('error');
        setErrorText('Ej giltig länk');
      } else if (action === 'error') {
        const errorDescription = new URLSearchParams(search).get(
          'error_description'
        );
        setErrorText(errorDescription);
        if (errorType === 'accounting') {
          setErrorDetails(
            'Kontrollera att du har alla behörigheter i bokföringssystemet.'
          );
        }
        setAuthState('error');
      }
    };
    onAction();
  }, [action]);

  const redirectToFortnox = async () => {
    await AuthService.getAuthUrlFortnox(
      4, // Redirect back to external auth page for fortnox client
      null, // Client Id. Not yet created.
      0, // Create client
      externalAuthState.id // External auth state id to keep track of
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };

  const redirectToVisma = async () => {
    await AuthService.getAuthUrlVisma(
      4, // Redirect back to external auth page for visma client
      null, // Client Id. Not yet created.
      0, // Create client
      externalAuthState.id // External auth state id to keep track of
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };

  const redirectToBank = async (bankName, bankCountry, psuType) => {
    await BankService.getAuthLinkForEnableBanking(
      externalAuthState.targetClient,
      bankName,
      bankCountry,
      psuType,
      'external',
      externalAuthState.id
    )
      .then((response) => {
        if (response) {
          window.location.replace(response.data);
        }
      })
      .catch((error) => {
        setAuthState('error');
        setErrorText('Kunde inte omdirigera till bank, försök igen senare');
      });
  };

  const openSkvTab = async () => {
    setLoadingText('Omdirigerar...');
    setAuthState('loading');
    await SKVService.getDeepLinkSKV(externalAuthState.targetClient)
      .then((response) => {
        setPollingOmbud(true);
        setLoadingText('Väntar på autentisering...');
        window.open(response.data, '_blank', 'noopener,noreferrer');
      })
      .catch(() => {
        setErrorText(
          'Kunde inte omdirigera till Skatteverket, försök igen senare'
        );
        setAuthState('error');
      });
  };

  async function NextStep() {
    if (authState === 'bokföring') {
      if (banks.length === 0 && externalAuthState.bankConnected === false) {
        await GetBanks()
          .then(() => setAuthState('bank'))
          .catch(() => {});
      } else {
        setAuthState('bank');
      }
    } else if (authState === 'bank') {
      setAuthState('skatteverket');
    }
  }

  async function PreviousStep() {
    if (authState === 'skatteverket') {
      if (banks.length === 0 && externalAuthState.bankConnected === false) {
        await GetBanks()
          .then(() => setAuthState('bank'))
          .catch(() => {});
      } else {
        setAuthState('bank');
      }
    } else if (authState === 'bank') {
      setAuthState('bokföring');
    }
  }

  return (
    <div className="external-auth-wrapper">
      {logo && (
        <div className="auth-header">
          <img className="logo" src={logo} alt="Logo" />
        </div>
      )}
      <XpektorModal>
        <XpektorModal.Header
          title={
            authState === 'bokföring'
              ? 'Autentisering mot bokföringssystem'
              : authState === 'bank'
              ? 'Autentisering mot bank'
              : authState === 'skatteverket'
              ? 'Autentisering mot skatteverket'
              : authState === 'error'
              ? 'Något gick fel!'
              : authState === 'completed'
              ? 'Autentisering slutförd'
              : ''
          }
        />
        <XpektorModal.Body>
          {authState === 'loading' ? (
            <LoadingBar active status={loadingText} />
          ) : (
            externalAuthState !== null && (
              <>
                <AuthStepDisplay
                  step={
                    authState === 'bokföring'
                      ? 1
                      : authState === 'bank'
                      ? 2
                      : authState === 'skatteverket'
                      ? 3
                      : 0
                  }
                  totalSteps={3}
                >
                  <AuthStepIcon
                    selected={authState === 'bokföring'}
                    connected={externalAuthState.accountingConnected === true}
                  />
                  <AuthStepIcon
                    selected={authState === 'bank'}
                    connected={externalAuthState.bankConnected === true}
                  />
                  <AuthStepIcon
                    selected={authState === 'skatteverket'}
                    connected={externalAuthState.skvConnected === true}
                  />
                </AuthStepDisplay>
                {authState === 'bokföring' && (
                  <AccountingAuthStep
                    completed={externalAuthState.accountingConnected === true}
                    redirectToFortnox={redirectToFortnox}
                    redirectToVisma={redirectToVisma}
                  />
                )}
                {authState === 'bank' && (
                  <BankAuthStep
                    completed={externalAuthState.bankConnected === true}
                    banks={banks}
                    redirectToBank={redirectToBank}
                  />
                )}
                {authState === 'skatteverket' && (
                  <SkvAuthStep
                    allowed={
                      externalAuthState.accountingConnected === true ||
                      externalAuthState.bankConnected === true
                    }
                    completed={externalAuthState.skvConnected === true}
                    openSkvTab={openSkvTab}
                  />
                )}
                {authState !== 'error' && authState !== 'completed' && (
                  <div className="auth-step-nav">
                    {authState !== 'bokföring' && (
                      <XpektorButton
                        content="Tillbaka"
                        onClick={() => PreviousStep()}
                      />
                    )}
                    <div className="auth-step-nav-info">
                      {authState === 'bokföring' &&
                        externalAuthState.accountingConnected === false &&
                        externalAuthState.targetClient !== null && (
                          <p>Saknar du affärssystemen ovan?</p>
                        )}
                    </div>
                    {authState !== 'skatteverket' &&
                      externalAuthState.targetClient !== null && (
                        <XpektorButton
                          content={
                            authState === 'bokföring' &&
                            externalAuthState.accountingConnected === false
                              ? 'Hoppa över'
                              : 'Nästa steg'
                          }
                          onClick={() => NextStep()}
                        />
                      )}
                  </div>
                )}
              </>
            )
          )}
          {authState === 'error' && (
            <div className="auth-step">
              <div className="auth-error-content">
                <p>{errorText}</p>
                <p>{errorDetails}</p>

                <div className="auth-error-nav">
                  {errorType === 'accounting' && (
                    <div className="auth-help-button">
                      <XpektorButton
                        icon={faCircleQuestion}
                        content="Hjälp"
                        onClick={() => setShowFortnoxTutorial(true)}
                      />
                    </div>
                  )}
                  <div />
                  <XpektorButton
                    content="Fortsätt"
                    onClick={() => {
                      window.location = `/authorization?action=auth&ext=${ext}`;
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {authState === 'completed' && (
            <div className="auth-step">
              <div className="auth-step-completed-content">
                <FontAwesomeIcon
                  className="auth-complete"
                  icon={faCircleCheck}
                />
                <p>Alla steg genomförda!</p>
              </div>
            </div>
          )}
        </XpektorModal.Body>
      </XpektorModal>
      <div className="auth-footer">
        <p>Powered by</p>
        <img className="logo" src={XpektorLogo} alt="XpektorLogo" />
      </div>
      {showFortnoxTutorial && (
        <AccountingTutorialModal
          partnerId={partnerId !== null && Number(partnerId)}
          handleClose={() => setShowFortnoxTutorial(false)}
        />
      )}
    </div>
  );
}
