import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import LandingNavigation from './LandingNavigation/LandingNavigation';
import './LandingSpot.scss';
import QuoteMark from '../../Images/Quote.svg';
import DarkQuote from '../../Images/DarkQuote.svg';
import Contact from '../../Components/Contact/Contact';
import Notis from '../../Images/LandingSpot/notiser i realtid.svg';
import Overblick from '../../Images/LandingSpot/getOverview.svg';
import PlugIn from '../../Images/LandingSpot/pluginSolution.svg';

import IntroImage from '../../Images/LandingSpot/xpektor_moln_ny.png';
import ProduktPeek from '../../Images/LandingSpot/landing-product-peek.svg';
import SwipeThing from './Swiper';
import XpektorButton from '../../Components/XpektorButton/XpektorButton';

export default function LandingSpot() {
  return (
    <div className="landing-spot-wrapper">
      <Row className="landing-page-intro">
        <LandingNavigation />
        <div className="landing-intro-content">
          <div className="landing-intro-column">
            <h1 className="landing-content-title">
              Att ta beslut i realtid har aldrig varit enklare och säkrare
            </h1>
            <p>
              Xpektor är en unik tjänst som använder realtidsläsning från bank,
              affärssystem, Skatteverket och Bolagsverket. Med full insyn får ni
              stöd att fatta beslut, identifiera affärsmöjligheter och undvika
              fallgropar.
            </p>
            <p className="p-intro-bold">
              Glöm historisk data och titta in i framtiden med Xpektor och
              prediktiva resultat.
            </p>
            <a
              href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-demo-button"
            >
              <XpektorButton content="Boka demo" />
            </a>
          </div>
          <div className="landing-intro-column intro-image-column">
            <img
              src={IntroImage}
              alt="Intro alt"
              className="landing-intro-image"
            />
          </div>
        </div>
        <div className="look-text-container">
          <p>TITTA HIT</p>
          <FontAwesomeIcon
            icon={faAngleDoubleDown}
            className="look-text-icon"
          />
        </div>
      </Row>
      <div className="landing-second-content">
        <h1>
          Xpektor är ett state-of-the-art säkerhetssystem som sätter stopp för
          bedrägerier och välfärdsbrott.
        </h1>
        <div className="second-content-row">
          <div className="second-content-column">
            <p>
              Vi är dock mycket mer än så. Vi skapar förutsättningar för våra
              kunder att fatta viktiga affärskritiska beslut och identifiera
              affärsmöjligheter i realtid. Kraften i Xpektor gör det möjligt för
              våra kunder att agera på prediktiva resultat, inte historisk data.
              Kredit och risk har aldrig varit lättare att analysera.
            </p>
            <div className="second-content-items-column">
              <div className="second-content-item">
                <h4>VARFÖR</h4>
                <p>
                  I en tid av skenande bedrägerier, penningtvätt och
                  välfärdsbrott behövs Xpektor mer än någonsin.
                  Företagsinformation kan idag delas både tryggt och enkelt
                  vilket Xpektor tar till vara på. Tiden att fatta beslut på
                  historisk data och ofullständiga uppgifter är förbi.
                </p>
              </div>
              <div className="second-content-item">
                <h4>HUR</h4>
                <p>
                  Xpektor ger er tillgång till kritisk information som behövs
                  för att fatta affärsmässiga beslut. Xpektor tolkar och
                  analysera data från ett antal källor och är ensamma om att
                  skapa insikter utifrån dessa källor. Med hjälp av Mobilt
                  Bankid är det både tryggt och enkelt att dela information med
                  Xpektor.
                </p>
              </div>
              <div className="second-content-item">
                <h4>FÖR VEM</h4>
                <p>
                  Xpektor servar kunder inom ett antal segment så som: banker,
                  finansinstitut, redovisningskonsulter, revisionsbyråer,
                  kommuner och styrelsemedlemmar.
                  <p>
                    <br />
                    Vill du veta mer om hur vi kan hjälpa dig?
                    <br />
                    <a
                      href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Klicka här för att komma i kontakt
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className="second-content-column">
            <img
              src={ProduktPeek}
              alt="Product peek"
              className="landing-product-image"
            />
          </div>
        </div>
      </div>

      <div className="d-none d-lg-block">
        <Row className="px-3 py-3 text-center">
          <div className="col-4">
            <Link to="/product">
              <Image fluid src={Notis} />
            </Link>
          </div>
          <div className="col-4">
            <Link to="/product#overview">
              <Image fluid src={Overblick} />
            </Link>
          </div>
          <div className="col-4">
            <Link to="/product#start">
              <Image fluid src={PlugIn} />
            </Link>
          </div>
        </Row>
      </div>

      <Container className="swipeContainer d-block d-lg-none">
        <SwipeThing />
      </Container>

      <Row className="centerContainer container-fluid bg-transparent m-0">
        <Col className="col-12 col-lg-5">
          <div className="fistColumn px-5">
            <Row className="">
              <div className="col-2 text-end">
                <Image fluid src={QuoteMark} />
              </div>
              <h4 className="col-10">
                Digitalisering gör att flödena i ett bolag går allt snabbare. Vi
                som rådgivare hinner inte alltid agera i tid...
              </h4>
            </Row>
            <p className="text-start text-md-end ps-5 ps-md-0">
              Anders Jakobsson, Redovisningskonsult & kvalitetsansvarig
            </p>
            <div className="text-end pb-5">
              <a href="/target">
                <p className="text-uppercase">
                  Läs mer
                  <FontAwesomeIcon
                    className="ms-3 mt-4"
                    icon={faAngleDoubleRight}
                    size="sm"
                  />
                </p>
              </a>
            </div>
          </div>
        </Col>
        <Col className="chess-container col-12 col-lg-7">
          <div className="d-none d-lg-block" style={{ height: 365 }} />
          <div className="py-5 mt-5 text-start pe-3">
            <h1 className="mt-5 text-white">
              Xpektor ser in i framtiden och ger dig tid att agera.
            </h1>
          </div>
        </Col>
      </Row>

      <Row>
        <div className="titleDiv px-5 pt-5 mt-5">
          <h1>Läs om hur allt började.</h1>
        </div>
        <Container className="officeImg col-12 col-lg-6 mb-5">
          <Row>
            <div className="d-none d-lg-block col-4">
              <div style={{ height: 350 }} />
              <p className="ps-4 text-white">Daniel Eremar, Head of Sales</p>
            </div>
            <div className="col-lg-8">
              <div
                className="d-none d-lg-block align-items-center"
                style={{ height: 200 }}
              >
                <div style={{ height: 120 }} />
                <p className="text-white text-start ms-5">
                  Hanna Gårdmark, CEO
                </p>
              </div>
              <div className="quoteBox">
                <Container>
                  <Row className="pt-3">
                    <div className="col-1 pt-3 px-2 px-md-0 text-start text-md-end">
                      <Image src={DarkQuote} />
                    </div>
                    <div className="col-11 pe-3 pt-2 pt-md-0 text-start">
                      <p>
                        Efter att ha genomfört ett omfattande
                        digitaliseringsarbete av kunderna på vår
                        redovisningsbyrå började vi ifrågasatta oss själva. När
                        allt sker på automatik, hur kan vi ändå inte upptäcka
                        att bolag är på väg att gå i konkurs?
                      </p>
                      <div className="text-end">
                        <a href="/about">
                          <p>
                            VI BERÄTTAR MER HÄR{' '}
                            <FontAwesomeIcon
                              className="ms-3"
                              icon={faAngleDoubleRight}
                              size="sm"
                            />
                          </p>
                        </a>
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
              <div className="d-none d-lg-block" style={{ height: 30 }} />
            </div>
          </Row>
        </Container>
        <Container className="col-12 col-lg-6 mt-5">
          <Row className="py-3">
            <Container className="col-2 text-end">
              <FontAwesomeIcon
                className="icons mt-3"
                icon={faAngleDoubleRight}
                size="lg"
              />
            </Container>
            <Container className="col-10 text-start pe-5 quote">
              <h2>När händelser kan förutspås, kan fallgropar undvikas</h2>
            </Container>
          </Row>
          <Row className="py-3">
            <Container className="col-2 text-end">
              <FontAwesomeIcon
                className="icons mt-3"
                icon={faAngleDoubleRight}
                size="lg"
              />
            </Container>
            <Container className="col-10 text-start pe-5">
              <h2>
                Plattformen där affärskritiska insikter leder till enkla beslut
              </h2>
            </Container>
          </Row>
          <Row className="py-3">
            <Container className="col-2 text-end">
              <FontAwesomeIcon
                className="icons mt-3"
                icon={faAngleDoubleRight}
                size="lg"
              />
            </Container>
            <Container className="col-10 text-start pe-5">
              <h2>Xpektor kan förutspå så att ni kan föregå</h2>
            </Container>
          </Row>
        </Container>
      </Row>

      <header />

      <header className="d-none d-md-block" />

      <footer>
        <Contact />
      </footer>
    </div>
  );
}
