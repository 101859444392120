/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Moment from 'moment';
import ClientService from '../../../../Services/ClientService';
import StatusService from '../../../../Services/StatusService';
import Module from './Module';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';

export default function Regulatory({ client, skvConnected }) {
  const [regulatoryInfo, setRegulatoryInfo] = useState({});
  const [payrollData, setPayrollData] = useState([]);
  const [activeDirectors, setActiveDirectors] = useState([]);
  const [inactiveDirectors, setInactiveDirectors] = useState([]);
  const [ongoingMatters, setOngoingMatters] = useState([]);
  const [fetchingRegulatory, setFetchingRegulatory] = useState(false);
  const [regulatoryStatus, setRegulatoryStatus] = useState(null);

  const [displayingAllDirectors, setDisplayingAllDirectors] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setFetchingRegulatory(true);

      await ClientService.getRegulatoryInfo(client.id)
        .then((response) => {
          setRegulatoryInfo(response.data);
          setActiveDirectors(response.data.activeDirectors);
          setInactiveDirectors(response.data.inactiveDirectors);
          setPayrollData(response.data.payrollData);
          setOngoingMatters(response.data.ongoingMatters);
        })
        .catch(() => {
          setRegulatoryInfo(null);
        })
        .finally(() => {
          setFetchingRegulatory(false);
        });
    };
    if (Object.keys(client).length !== 0 && client.errorCode === null) {
      getData();
    }
  }, [client]);

  useEffect(() => {
    const getData = async () => {
      await StatusService.getRegulatoryLatest(client.id)
        .then((response) => {
          console.log(response.data);
          setRegulatoryStatus(response.data);
        })
        .catch(() => {});
    };
    if (skvConnected === true) {
      getData();
    }
  }, [skvConnected]);

  async function ToggleAllDirectors() {
    if (!displayingAllDirectors) {
      setDisplayingAllDirectors(true);
    } else {
      setDisplayingAllDirectors(false);
    }
  }

  const sortedPayrollData = payrollData.sort(
    (a, b) => new Date(b.payrollDate) - new Date(a.payrollDate)
  );

  return (
    <Module>
      {client.errorCode !== null ? (
        <p className="module-empty-text">{client.errorCode}</p>
      ) : fetchingRegulatory ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : regulatoryInfo === null ? (
        <p className="module-empty-text">Finns ingen data.</p>
      ) : (
        <Module.DataSection>
          <Module.DataColumn>
            <DataPanel title="Företagsinformation">
              <DataPanel.ValueEntry
                label="Företagstyp:"
                value={regulatoryInfo?.companyType ?? 'Uppgift saknas'}
              />
              <DataPanel.ValueEntry
                label="Aktivt:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.companyActive === true
                      ? 'Ja'
                      : 'Nej'
                    : 'Uppgift Saknas'
                }
              />
              <DataPanel.ValueEntry
                label="Antal anställda:"
                value={regulatoryInfo?.nrEmployees ?? 'Uppgift saknas'}
              />

              <DataPanel.ValueEntry
                label="Senaste adressändring:"
                value={
                  regulatoryInfo?.dateAddressChanged
                    ? Moment(regulatoryInfo.dateAddressChanged).format(
                        'YYYY-MM-DD'
                      )
                    : 'Uppgift saknas'
                }
              />
              <DataPanel.ValueEntry
                label="Tidigare namn:"
                value={regulatoryInfo?.oldName ?? 'Uppgift saknas'}
              />
              <DataPanel.ValueEntry
                label="Namn på innehavare (enskild firma):"
                value={regulatoryInfo?.nonLtdOwnerName ?? 'Uppgift saknas'}
              />

              <DataPanel.ValueEntry
                label="Moderbolag:"
                value={regulatoryInfo?.groupMotherName ?? 'Uppgift saknas'}
              />
            </DataPanel>
            {regulatoryStatus !== null && (
              <DataPanel title="Skatteverket">
                <DataPanel.ValueEntry
                  label="Saldo skattekonto"
                  value={regulatoryStatus.skatteverketBalance}
                />
              </DataPanel>
            )}
            <DataPanel title="Inbetalda sociala avgifter" scroll height={550}>
              {sortedPayrollData.map((data) => {
                return (
                  <DataPanel.DoubleValueEntry
                    key={data.id}
                    title={`${data.fee} kr`}
                    valueOne={Moment(data.payrollDate).format('YYYY-MM-DD')}
                    valueTwo={Moment(data.registerDate).format('YYYY-MM-DD')}
                    valueOneName="Datum:"
                    valueTwoName="Registrerat:"
                  />
                );
              })}
            </DataPanel>
          </Module.DataColumn>
          <Module.DataColumn>
            <DataPanel title="Styrelsemedlemmar">
              <DataPanel.Subtitle title="AKTIVA" green />
              {activeDirectors.length === 0 ? (
                <DataPanel.ValueEntry label="Ingen Data" />
              ) : (
                activeDirectors.map((director, index) => {
                  return (
                    (!displayingAllDirectors ? index < 5 : true) && (
                      <DataPanel.DoubleValueEntry
                        key={director.id}
                        title={director.name}
                        subTitle={director.function}
                        valueOne={Moment(director.appointmentDate).format(
                          'YYYY-MM-DD'
                        )}
                        valueTwo={
                          director.exitingDate
                            ? Moment(director.exitingDate).format('YYYY-MM-DD')
                            : null
                        }
                        valueOneName="Tillträdde:"
                        valueTwoName="Avgick:"
                      />
                    )
                  );
                })
              )}
              {displayingAllDirectors && (
                <DataPanel.Subtitle title="INAKTIVA" red />
              )}
              {displayingAllDirectors ? (
                inactiveDirectors.length !== 0 ? (
                  inactiveDirectors.map((director) => {
                    return (
                      <DataPanel.DoubleValueEntry
                        key={director.id}
                        title={director.name}
                        subTitle={director.function}
                        valueOne={Moment(director.appointmentDate).format(
                          'YYYY-MM-DD'
                        )}
                        valueTwo={
                          director.exitingDate
                            ? Moment(director.exitingDate).format('YYYY-MM-DD')
                            : null
                        }
                        valueOneName="Tillträdde:"
                        valueTwoName="Avgick:"
                      />
                    );
                  })
                ) : (
                  <DataPanel.ValueEntry label="Ingen Data" />
                )
              ) : (
                ''
              )}
              <DataPanel.ExpandButton
                text={displayingAllDirectors ? 'Minimera' : 'Expandera'}
                expanded={displayingAllDirectors}
                onClick={() => {
                  ToggleAllDirectors();
                }}
              />
            </DataPanel>
          </Module.DataColumn>
          <Module.DataColumn>
            <DataPanel title="Regulatoriska krav">
              <DataPanel.ValueEntry
                label="F-skatt:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.fTax === true
                      ? 'Ja'
                      : regulatoryInfo.fTaxDeregistrationReason !== null
                      ? `Avregistrerad: ${regulatoryInfo.fTaxDeregistrationReason}`
                      : 'Nej'
                    : 'Uppgift saknas'
                }
                valueType=""
              />
              <DataPanel.ValueEntry
                label="Momsregistrering:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.moms === true
                      ? 'Ja'
                      : 'Nej'
                    : 'Uppgift saknas'
                }
                valueType=""
              />
              <DataPanel.ValueEntry
                label="Registrerad i arbetsgivarregistret:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.empTax === true
                      ? 'Ja'
                      : 'Nej'
                    : 'Uppgift saknas'
                }
              />
              <DataPanel.ValueEntry
                label="Revisorsförbehåll:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.auditorReservation === true
                      ? 'Ja'
                      : 'Nej'
                    : 'Uppgift saknas'
                }
              />
              <DataPanel.ValueEntry
                label="Företrädarkontroll:"
                value={
                  regulatoryInfo
                    ? regulatoryInfo.representativeCheck === true
                      ? 'Negativ information finns'
                      : 'Ingen negativ information finns'
                    : 'Uppgift saknas'
                }
              />
            </DataPanel>
            <DataPanel title="Pågående ärenden">
              {ongoingMatters.length === 0 ? (
                <DataPanel.ValueEntry label="Ingen Data" />
              ) : (
                ongoingMatters.map((data) => {
                  return (
                    <DataPanel.DoubleValueEntry
                      key={data.id}
                      title={data.matterText}
                      subTitle={data.caseNumber}
                      valueOne={
                        data.dateAdded &&
                        Moment(data.dateAdded).format('YYYY-MM-DD')
                      }
                      valueOneName="Datum:"
                    />
                  );
                })
              )}
            </DataPanel>
          </Module.DataColumn>
        </Module.DataSection>
      )}
    </Module>
  );
}
