import { Button, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import UserService from '../../../../Services/UserService';
import validateSettingsForm from '../validateSettingsForm';
import CustomerService from '../../../../Services/CustomerService';
import UserClientAccessService from '../../../../Services/UserClientAccessService';

/* eslint-disable react/prop-types */

// eslint-disable-next-line react/prop-types
function AddUserPopup({ show, handleClose }) {
  let userErrors;
  const formNumber = 3;
  const [errors, setErrors] = useState({});
  const [showBody, setShowBody] = useState(1);
  const [user, setUser] = useState({});
  const [clients, setClients] = useState({});
  const [selectedClients, setSelectedClients] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const currentUser = await UserService.getCurrentUser();
      await CustomerService.getClients(currentUser.customerId)
        .then((response) => {
          setClients(response.data.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('Hittar inga klienter');
          }
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
        });
    };
    getData();
    setUser({
      customerId: UserService.getCurrentUser().customerId,
      email: '',
      password: '',
      name: '',
      role: 0,
    });
  }, []);

  const submitUserClientAccess = (userId) => {
    Object.values(selectedClients).forEach((value) => {
      UserClientAccessService.GetOrCreateUserClientAccess(userId, value);
    });
  };

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) {
      alert('Det finns fel i formuläret');
    } else {
      await UserService.CreateUser(user)
        .then(async (response) => {
          if (response) {
            const userResponse = JSON.parse(response.request.response);
            await submitUserClientAccess(userResponse.id);
            alert('Användare skapad');
            setShowBody(1);
            setUser({});
            setSelectedClients([]);
            handleClose();
            window.location = '/settings/users';
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            alert(
              'Användare kunde inte skapas: Det finns redan en användare med denna email-adress.'
            );
          }
          if (error.response.status === 404) {
            alert('Kunde inte lägga till användare');
          }
          if (error.response.status === 403) {
            alert('Du har ej behörighet att skapa användare');
          }
          if (error.response.status === 500) {
            alert('Något gick fel, försök igen senare');
          }
        });
    }
  };

  const handleCheckChange = (e) => {
    const { checked, value } = e.currentTarget;
    setSelectedClients((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const handleChange = (e, set) => {
    userErrors = validateSettingsForm(formNumber, set, e.target.value);
    setErrors(userErrors);

    const newValue =
      e.target.name === 'role' ? Number(e.target.value) : e.target.value;
    setUser((prevState) => ({
      ...prevState,
      [e.target.name]: newValue,
    }));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0"
    >
      <Modal.Header className="border-0">
        <Modal.Title>Skapa ny användare</Modal.Title>
        <Button
          className="btn-close"
          bsPrefix="button"
          onClick={() => {
            setShowBody(1);
            setUser({});
            setSelectedClients([]);
            handleClose();
          }}
        />
      </Modal.Header>

      {showBody === 1 ? (
        <Modal.Body>
          <Form>
            <Form.Group>
              <TextField
                type="text"
                name="name"
                label="För och Efternamn"
                placeholder="För och Efternamn"
                variant="outlined"
                onChange={(e) => handleChange(e, 1)}
                value={user.name}
                error={errors.newUserName}
                helperText={errors.newUserName}
                className="w-100"
              />
            </Form.Group>
            <br />
            <Form.Group>
              <TextField
                type="text"
                variant="outlined"
                name="email"
                placeholder="Email"
                label="Email"
                onChange={(e) => handleChange(e, 2)}
                value={user.email}
                error={errors.newEmail}
                helperText={errors.newEmail}
                className="w-100"
              />
            </Form.Group>
            <br />
            <Form.Group>
              <TextField
                type="password"
                name="password"
                label="Lösenord"
                variant="outlined"
                placeholder="Tillfälldigt lösenord"
                onChange={(e) => handleChange(e, 3)}
                value={user.password}
                error={errors.newPw}
                helperText={errors.newPw}
                className="w-100"
              />
            </Form.Group>
            <br />
            <Form.Group>
              <select
                name="role"
                className="form-select"
                onChange={(e) => handleChange(e)}
              >
                <option value={0} defaultValue>
                  Användare
                </option>
                <option value={1}>Admin</option>
              </select>
            </Form.Group>
          </Form>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Klienter användaren ska ha tillgång till:</Form.Label>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  overflow: 'auto',
                  maxHeight: 300,
                  border: 1,
                  borderColor: 'grey.500',
                }}
              >
                {clients.map((client, i) =>
                  client.name ? (
                    <ListItem className="form-check" key={client.id}>
                      <ListItemButton
                        className="w-100"
                        value={client?.id}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            type="checkbox"
                            name={i}
                            value={client.id}
                            id="flexCheckDefault"
                            onChange={handleCheckChange}
                            checked={selectedClients.includes(client.id)}
                          />
                          <ListItemText
                            className="pt-2"
                            primary={client.name}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    ''
                  )
                )}
              </List>
            </Form.Group>
          </Form>
        </Modal.Body>
      )}

      {showBody === 1 ? (
        <Modal.Footer className="border-0">
          <div className="flex-row">
            <div className="col-6">
              <Button
                className="btn btn-primary btn-sm"
                bsPrefix="footer-button"
                onClick={() => setShowBody(2)}
              >
                Nästa
              </Button>
            </div>
          </div>
        </Modal.Footer>
      ) : (
        <Modal.Footer className="border-0">
          <Row>
            <div className="col-5">
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => setShowBody(1)}
              >
                Tillbaka
              </Button>
            </div>
            <div className="col-7">
              <Button
                className="btn btn-primary btn-sm text-nowrap w-auto"
                onClick={handleSubmit}
              >
                Skapa användare
              </Button>
            </div>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default AddUserPopup;
