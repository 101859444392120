/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import SKVLogo from '../../../Images/Logos/SKV_RGB_li.png';

export default function SkvAuthStep({ allowed, completed, openSkvTab }) {
  return completed ? (
    <div className="auth-step-completed-content">
      <FontAwesomeIcon className="auth-complete" icon={faCircleCheck} />
      <p>Skatteverket är kopplat!</p>
    </div>
  ) : (
    <div className="auth-step">
      <p className="auth-step-description">
        En ytterligare fördel är att visa att företaget är i god ordning med
        skatter och efterlevnad av lagkrav. Via Skatteverket delar ni enkelt
        denna information.
        <br />
        <br />
        Obs! Att enkom koppla Skatteverket är inte möjligt då informationen
        tolkas och samkörs med andra delar.
      </p>
      <div className="integration-options">
        <div className="integration-partner">
          <img className="partner-logo" src={SKVLogo} alt="Skatteverket" />
          <XpektorButton
            content="Till Skatteverket"
            onClick={() => openSkvTab()}
            disabled={!allowed}
          />
          {!allowed && (
            <p className="info-bubble">
              Vänligen koppla bokföring och/eller bank först
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
