import './Navigation.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Searchbar from '../../../Components/Searchbar/Searchbar';
import UserService from '../../../Services/UserService';
// import ServiceStatusPopup from '../../../Components/ServiceStatusPopup/ServiceStatusPopup';

export default function Navigation() {
  const [authState, setAuthState] = useState({});
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const user = UserService.getCurrentUser();
    setAuthState((state) => ({ ...state, user }));
  }, []);

  const onSubmit = (e) => {
    history.push(`/dashboard/?s=${searchQuery}`);
    e.preventDefault();
  };

  return (
    <div className="navbar-wrapper">
      <div className="content-container">
        <Searchbar
          placeholder="Sök klient"
          onChange={(e) => setSearchQuery(e.target.value)}
          onSubmit={onSubmit}
        />
        {/* <ServiceStatusPopup
          text="Just nu finns det ett partiellt systemavbrott hos Fortnox som gör att vi
      inte kan uppdatera all data."
  /> */}
        <div className="user-name">
          <p>{authState.user ? authState.user.name : ''}</p>
        </div>
      </div>
    </div>
  );
}
