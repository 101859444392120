/* eslint-disable no-alert */
import axios from 'axios';
import TokenService from './TokenService';
import api from './ApiMiddleware';

const login = async (username, password) => {
  return axios
    .post(`/api/v1/identity/login`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token && !TokenService.getLocalAccessToken()) {
        TokenService.setToken(response.data);
      }
      return response.data;
    });
};

const decode = async (token) => {
  return axios
    .get(`/api/v1/identity/state/${token}`)
    .then((response) => {
      if (response.data.authResult.token && response.data.stateTargetLocation) {
        TokenService.setToken(response.data.authResult);
        switch (response.data.stateAction) {
          case 0: // Create client
            return response.data.stateTargetLocation === 4
              ? `/authorization?ext=${response.data.externalStateId}`
              : `/settings/clients?action=createClient&customerId=${response.data.authResult.user.customerId}`;
          case 1: // Update client
            return `/settings/clients?action=updateClient&clientId=${response.data.targetId}`;
          case 2: // Login
            return response.data.stateTargetLocation === 0
              ? `/companyinfo/${response.data.targetId}/equity`
              : `/dashboard`;
          case 3: // Authorize bank.
            return response.data.stateTargetLocation === 4
              ? `?clientId=${response.data.targetId}&target=external&ext=${response.data.externalStateId}`
              : `?clientId=${response.data.targetId}&target=internal`;
          default: // External auth default
            return `/authorization?action=auth&ext=${response.data.externalStateId}`;
        }
      }
      return false;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        alert('Token har gått ut');
        return `/dashboard`;
      }
      if (error.response.status === 500) {
        alert('Något gick fel försök igen om en stund');
        return `/dashboard`;
      }
      return false;
    });
};

const decodePresigned = async (token) => {
  return axios
    .get(`/api/v1/identity/state/${token}`)
    .then((response) => {
      if (response.data.authResult.token) {
        TokenService.setToken(response.data.authResult);
        switch (response.data.stateTargetLocation) {
          case 0: // Create client
            return `/companyinfo/${response.data.targetId}/equity`;
          case 1: // Update client
            return `/dashboard`;
          default: // Login
            return `/dashboard`;
        }
      }
      return false;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        alert('Token har gått ut');
        return `/dashboard`;
      }
      if (error.response.status === 500) {
        alert('Något gick fel försök igen om en stund');
        return `/dashboard`;
      }
      return false;
    });
};

const decodeExternal = async (token) => {
  return axios
    .get(`/api/v1/identity/state/auth/${token}`)
    .then((response) => {
      if (response.data.authResult.token) {
        TokenService.setToken(response.data.authResult);
        return response.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
};

const getAllExternalStates = async () => {
  return api.get(`/api/v1/state/external/all`);
};

const updateExternalAuthState = async (updatedAuthState) => {
  return api.put(`/api/v1/state/external/${updatedAuthState.id}`, {
    targetClient: updatedAuthState.targetClient,
  });
};

const createExternalAuthState = async (clientId, redirectUrl) => {
  return api.post(`/api/v1/state/external`, {
    clientId,
    redirectUrl,
  });
};

const getExternalAuthState = async (token) => {
  return api.get(`/api/v1/state/external/${token}`);
};

const getClientExternalAuthState = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/state/external`);
};

const deleteExternalAuthState = async (token) => {
  return api.delete(`/api/v1/state/external/${token}`);
};

const getAuthUrlFortnox = async (
  returnLocation,
  targetId,
  stateAction,
  externalStateId
) => {
  return api
    .post(`/api/v1/identity/authurl/fortnox`, {
      returnLocation,
      stateAction,
      targetId,
      externalStateId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      }

      return false;
    });
};

const getAuthUrlVisma = async (
  returnLocation,
  targetId,
  stateAction,
  externalStateId
) => {
  return api
    .post(`/api/v1/identity/authurl/visma`, {
      returnLocation,
      stateAction,
      targetId,
      externalStateId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      }

      return false;
    });
};

const generateExternalAuthUrlForClient = async (clientId, redirectUrl) => {
  return api.post(`/api/v1/identity/external/authurl`, {
    clientId,
    redirectUrl,
  });
};

const logout = () => {
  TokenService.removeToken();
};

const getCurrentUser = () => {
  return TokenService.getLocalUser();
};

const AuthService = {
  login,
  getAuthUrlFortnox,
  getAuthUrlVisma,
  getAllExternalStates,
  updateExternalAuthState,
  createExternalAuthState,
  getExternalAuthState,
  getClientExternalAuthState,
  deleteExternalAuthState,
  generateExternalAuthUrlForClient,
  decode,
  decodePresigned,
  decodeExternal,
  logout,
  getCurrentUser,
};

export default AuthService;
