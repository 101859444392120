/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleMinus,
} from '@fortawesome/free-solid-svg-icons';

export function AuthStepDisplay({ children, step, totalSteps }) {
  return (
    step !== 0 && (
      <div className="auth-step-display">
        <div className="auth-step-icons">{children}</div>
        <p>{`Steg ${step} / ${totalSteps}`}</p>
      </div>
    )
  );
}

export function AuthStepIcon({ connected, selected }) {
  return (
    <FontAwesomeIcon
      icon={connected ? faCircleCheck : faCircleMinus}
      className={`step-icon ${
        connected ? 'auth-complete' : 'auth-incomplete'
      } ${selected && 'auth-step-selected'}`}
    />
  );
}
