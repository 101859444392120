import './PrivacyPolicy.scss';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';

export default function PrivacyPolicy() {
  return (
    <>
      <LandingNavigation />
      <div className="privacy-policy-wrapper">
        <p className="title section">Integritetspolicy för Xpektor Tech AB</p>
        <p className="section">
          {`Denna integritetspolicy förklarar hur Xpektor Tech AB (nedan "vi",
          "oss" eller "företaget") samlar in, använder, skyddar och hanterar
          dina personuppgifter enligt gällande lagstiftning, inklusive EU:s
          allmänna dataskyddsförordning (GDPR).`}
        </p>
        <div className="section">
          <p>{`1. Insamlade uppgifter `}</p>
          <p>{`Vi samlar in olika typer av personuppgifter för att kunna tillhandahålla och 
            förbättra våra tjänster. De typer av uppgifter vi kan samla in inkluderar:`}</p>
          <ul>
            <li>
              - Kontaktinformation såsom namn, adress, e-postadress,
              telefonnummer.
            </li>
            <li>
              - Identifieringsuppgifter såsom personnummer eller
              företagsregistreringsnummer.
            </li>
            <li>- Ekonomisk information såsom betalningsuppgifter.</li>
            <li>
              - Teknisk information såsom IP-adress, webbläsartyp,
              enhetsinformation.
            </li>
            <li>
              - Användaruppgifter såsom inloggningsinformation,
              användarinställningar.
            </li>
          </ul>
        </div>
        <div className="section">
          <p>2. Laglig grund för behandling</p>
          <p>Vi behandlar dina personuppgifter på följande lagliga grunder:</p>
          <ul>
            <li>
              - För att uppfylla avtal eller vidta åtgärder på begäran före
              avtalsslut.
            </li>
            <li>- För att uppfylla en rättslig förpliktelse.</li>
            <li>- Med ditt samtycke när så krävs enligt lag.</li>
            <li>
              - För att tillgodose våra berättigade intressen, såsom att
              förbättra och marknadsföra våra tjänster.
            </li>
          </ul>
        </div>
        <div className="section">
          <p>3. Användning av uppgifter</p>
          <p>Vi använder insamlade uppgifter för följande ändamål</p>
          <ul>
            <li>- För att tillhandahålla och underhålla våra tjänster.</li>
            <li>
              - För att kommunicera med dig angående dina förfrågningar, köp
              eller supportärenden.
            </li>
            <li>
              - För att anpassa och förbättra våra tjänster samt utveckla nya
              funktioner.
            </li>
            <li>
              - För att skicka relevant information, erbjudanden eller
              marknadsföring baserat på ditt samtycke eller våra berättigade
              intressen.
            </li>
          </ul>
        </div>
        <div className="section">
          <p>4. Datadelning</p>
          <p>
            Vi delar endast dina personuppgifter med tredje part när det är
            nödvändigt för att tillhandahålla våra tjänster eller enligt lagliga
            förpliktelser. Vi säkerställer att sådana tredje parter följer
            tillräckliga säkerhetsåtgärder för att skydda dina uppgifter.
          </p>
        </div>
        <div className="section">
          <p>5. Dataskydd</p>
          <p>
            Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
            skydda dina personuppgifter mot obehörig åtkomst, ändring, spridning
            eller förlust.
          </p>
        </div>
        <div className="section">
          <p>6. Dina rättigheter</p>
          <p>
            Du har rätt att begära tillgång till, rättelse av, eller radering av
            dina personuppgifter. Du har även rätt att invända mot viss
            behandling av dina uppgifter eller begära begränsning av
            behandlingen. Kontakta oss via nedan angivna kontaktuppgifter för
            att utöva dina rättigheter.
          </p>
        </div>
        <div className="section">
          <p>7. Ändringar i integritetspolicyn</p>
          <p>
            Vi kan komma att uppdatera vår integritetspolicy och meddela dig om
            väsentliga ändringar. Senaste uppdateringsdatum för policyn anges
            nedan.
          </p>
        </div>
        <div className="section">
          <p>8. Kontakt</p>
          <p>
            Om du har frågor angående vår integritetspolicy eller vår hantering
            av dina uppgifter, kontakta oss på:
          </p>
          <ul>
            <li>Xpektor Tech AB</li>
            <li>Adress: Olivedalsgatan 7B</li>
            <li>Telefon: +46 769-424744</li>
            <li>E-post: daniel@xpektor.se</li>
          </ul>
        </div>
        <div className="section">
          <p>Senast uppdaterad: 2024-02-15</p>
        </div>
        <p>---</p>
        <p>{`Denna integritetspolicy är utformad för att vara i linje med svenska lagar och 
            EU:s dataskyddsförordning (GDPR). Den tar hänsyn till de olika typer av 
            personuppgifter som Xpektor Tech AB kan hantera och beskriver tydligt hur vi 
            använder och skyddar denna information.`}</p>
      </div>
      <footer>
        <Contact />
      </footer>
    </>
  );
}
