/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';

export default function AccountingAuthStep({
  completed,
  redirectToFortnox,
  redirectToVisma,
}) {
  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon className="auth-complete" icon={faCircleCheck} />
          <p>Bokföringssystemet är kopplat!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-description">
            Xpektor genererar finansiella rapporter automatiskt så att ni kan ge
            en korrekt och aktuell bild av ert företags ekonomi. Det är både
            enkelt att koppla bokföringssystemet och ni slipper skicka rapporter
            manuellt.
          </p>
          <div className="integration-options">
            <div className="integration-partner">
              <img className="partner-logo" src={FortnoxLogo} alt="Fortnox" />
              <XpektorButton
                content="Till Fortnox"
                onClick={() => redirectToFortnox()}
              />
              <p className="info-bubble">
                OBS: krävs att du har en integrationslicens i Fortnox.
              </p>
            </div>
            <div className="integration-partner">
              <img className="partner-logo" src={VismaLogo} alt="Visma" />
              <XpektorButton
                content="Till Visma"
                onClick={() => redirectToVisma()}
              />
              <p className="info-bubble">
                OBS: krävs att du har API modulen aktiverad i Visma.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
